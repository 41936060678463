<template>
    <div>
        <div class="discount-page">
            <div v-if="!discountPointsSettings?.isHidden">
                <h1>{{ $t('discountPoints.title') }}</h1>

                <h1 class="points-count">{{ discountPoints.amount }}</h1>
                <p class="points-description">
                    {{ $t('discountPoints.theAmountOfPointsHaveExpiresAfter', { date: discountPoints.expirationDate }) }}
                </p>
            </div>

            <div v-if="!referralSetting?.isDisable">
                <h3>{{ $t('discountPoints.referralLink') }}</h3>
                <CRInput
                    disabled
                    with-copy-icon
                    v-model="referralLink"
                    :label="$t('discountPoints.linkToShare')"
                    :help-text="$t('discountPoints.copyThisLinkAndShare', { amount: 100 })"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import CRInput from '@/components/Common/Inputs/CRInput.vue';
    import { ReferralSettingApi, DiscountPointSettingApi } from '@/api';
    import { mapGetters } from 'vuex';

    export default {
        name: 'Discounts',
        components: { CRInput },
        data() {
            return {
                referralSetting: null,
                discountPointsSettings: null,
            };
        },
        computed: {
            ...mapGetters('auth', ['referralLink', 'discountPoints']),
        },
        methods: {
            async fetchDiscountPointsSettings() {
                try {
                    const response = await DiscountPointSettingApi.get();
                    this.discountPointsSettings = response.data;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },
            async fetchReferralSetting() {
                try {
                    const response = await ReferralSettingApi.get();
                    this.referralSetting = response.data;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },
        },
        async mounted() {
            await this.fetchDiscountPointsSettings();
            await this.fetchReferralSetting();
        },
    };
</script>

<style lang="scss" scoped>
    .discount-page {
        max-width: 633px;

        h1 {
            margin-top: 10px;
            margin-bottom: 32px;
        }

        .points-count {
            font-size: 80px;
            color: $primary;
            line-height: 90%;
        }

        .points-description {
            font-size: 14px;
            line-height: 18px;

            margin-bottom: 32px;
        }

        h3 {
            margin-bottom: 32px;
        }
    }
</style>
